import { Options, Vue } from 'vue-class-component';
import {
    Form as VForm,
    Field as VField,
    ErrorMessage as VError
} from 'vee-validate';
import { authService } from '@services/auth.service';
import { Prop } from 'vue-property-decorator';
import { ResetPasswordData } from '@/model/api/Auth';
import { AuthRoutesEnum } from '../../router';

@Options({
    components: { VForm, VField, VError }
})
export default class ResetPasswordPage extends Vue {
    @Prop()
    readonly token: string;

    body: ResetPasswordData;

    created() {
        this.body = {} as any;
        this.body.token = this.token;
    }

    async sendReset() {
        await this.$waitFor(async () => {
            await authService.setResetPassword(this.body);
            this.$successMessage(this.$t('reset_password.password_changed'));
            
            this.$router.replace({ name: AuthRoutesEnum.SIGN_IN });
        }, this.$t('reset_password.invalid_token'));
    }
}