import { Options, Vue } from 'vue-class-component';
import {
    Form as VForm,
    Field as VField,
    ErrorMessage as VError
} from 'vee-validate';
import { authService } from '@services/auth.service';

@Options({
    components: { VForm, VField, VError }
})
export default class LostPasswordPage extends Vue {

    email: string = null;

    async sendEmail() {
        await this.$waitFor(async () => {
            await authService.sendResetPassword({ email: this.email });                 
            this.$successMessage(this.$t('reset_password.email_sent'));

            this.email = null;
        }, this.$t('generic_error'));
    }
}