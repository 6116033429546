import { defineComponent } from "vue";

import {
    Form as VForm,
    Field as VField,
    ErrorMessage as VError
} from 'vee-validate';

import { useLoginLogic } from "../_common/login";

export default defineComponent({
    components: {
        VForm,
        VField,
        VError,
    },
    

    setup() {
        return useLoginLogic();
    }
})