import { MainRoutesEnum } from "@/router/MainRoutesEnum";
import { isMobile } from "@/utils/utils";
import { localStorageSVC } from "@plugins/LocalStorage-plugin";
import { useMessage } from "@plugins/Messages-plugin";
import { authService } from "@services/auth.service";
import { reactive } from "vue";
import { useRouter } from "vue-router";
import { AuthRoutesEnum } from "../../router";
import { authStore } from "../../store";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

interface FormState {
  email: string;
  password: string;
  rememberMe: boolean;
  is_mobile: boolean,
}

export function useLoginLogic() {
  const router           = useRouter();
  const { errorMessage } = useMessage();

  const form = reactive<FormState>({
    email      : null,
    password   : null,
    rememberMe : true,
    is_mobile  : isMobile()
  });

  async function doLogin(){
    try {
      const response = await authService.login(form)

      localStorageSVC.saveAccessToken(
        response.access_token, 
        !form.rememberMe
      );

      await authStore.actions.getMe();
      if (authStore.getters.userRole === UserRoleEnum.USER) {
        errorMessage('Accesso non consentito per questo utente');
        localStorageSVC.clearToken();
      } else {
        router.replace({ name: MainRoutesEnum.DASHBOARD, params: { fromLogin: 'true' }})
      }
  
    } catch (error: any) {
      errorMessage(error.data.message);
    }
  }

  return {
    form,
    forgotPasswordRoute : AuthRoutesEnum.FORGOTTEN_PWD,
    signUpRoute         : AuthRoutesEnum.SIGN_UP,

    doLogin
  }
}