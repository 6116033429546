import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { id: "auth_page" }
const _hoisted_2 = { class: "auth_form card" }
const _hoisted_3 = { class: "head" }
const _hoisted_4 = { class: "p-fluid" }
const _hoisted_5 = { class: "p-field" }
const _hoisted_6 = { class: "p-field" }
const _hoisted_7 = { class: "actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_VField = _resolveComponent("VField")!
  const _component_Button = _resolveComponent("Button")!
  const _component_VForm = _resolveComponent("VForm")!
  const _directive_t = _resolveDirective("t")!

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_VForm, {
        onSubmit: _ctx.sendReset,
        as: "form"
      }, {
        default: _withCtx(({ isSubmitting, meta: { valid } }) => [
          _createVNode("div", _hoisted_3, [
            _createVNode("h1", null, _toDisplayString(_ctx.$t('reset_password.title')), 1),
            _createVNode("p", null, _toDisplayString(_ctx.$t('reset_password.subtitle_password')), 1)
          ]),
          _createVNode("div", _hoisted_4, [
            _createVNode("div", _hoisted_5, [
              _withDirectives(_createVNode("label", null, null, 512), [
                [_directive_t, 'reset_password.input_new_psw_label']
              ]),
              _createVNode(_component_VField, {
                name: "password",
                modelValue: _ctx.body.password,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.body.password = $event)),
                rules: "required|passwordRegex"
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps({ autofocus: "" }, field, {
                    disabled: isSubmitting,
                    class: { 'p-invalid': dirty && !valid },
                    type: "password",
                    placeholder: _ctx.$t('reset_password.input_new_psw_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ]),
            _createVNode("div", _hoisted_6, [
              _withDirectives(_createVNode("label", null, null, 512), [
                [_directive_t, 'reset_password.input_confirm_psw_label']
              ]),
              _createVNode(_component_VField, {
                name: "confirm_password",
                modelValue: _ctx.body.confirm_password,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.body.confirm_password = $event)),
                rules: "required|passwordRegex"
              }, {
                default: _withCtx(({ field, meta: { dirty, valid } }) => [
                  _createVNode(_component_InputText, _mergeProps(field, {
                    disabled: isSubmitting,
                    class: { 'p-invalid': dirty && !valid },
                    type: "password",
                    placeholder: _ctx.$t('reset_password.input_confirm_psw_label')
                  }), null, 16, ["disabled", "class", "placeholder"])
                ]),
                _: 2
              }, 1032, ["modelValue"])
            ])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode(_component_Button, {
              label: _ctx.$t('reset_password.btn_submit_label'),
              type: "submit",
              disabled: !valid || isSubmitting,
              class: "bold"
            }, null, 8, ["label", "disabled"])
          ])
        ]),
        _: 1
      }, 8, ["onSubmit"])
    ])
  ]))
}